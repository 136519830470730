$font-size-largest: 70px;
$font-size-larger: 45px;
$font-size-normal: 30px;
$font-size-small: 20px;
$font-size-smaller: 16px;
$font-size-smallest: 14px;

$space-vertical-smallest: 4px;
$space-vertical-small: 7px;
$space-vertical: 10px;
$space-vertical-large: 15px;
$space-vertical-larger: $space-vertical-large * 2;
$space-vertical-largest: $space-vertical-larger * 2;
$space-horizontal-small: 10px;
$space-horizontal: 15px;
$space-horizontal-large: 25px;

$teamtailor-pink: #f43f85;
$dark-gray: #2c2c34;
$black: #111;
$white: #fff;
$negative-red: #e98686;
$positive-green: #b8e986;
$splash-background: rgba(#3f414c, .9);

$avatar-size: 70px;

$tablet: 768px;

$font-stack: 'Lato', sans-serif;

$splash-z-index: 10;
$splash-animation: .6s ease-in;

.row {
  flex: 1;
  padding: 0;

  &--double {
    flex: 2 2;
    flex-direction: row;
  }
}

.u-justify-content-center {
  justify-content: center;
}

.u-align-items-center {
  align-items: center;
}

.u-flex-direction-col {
  flex-direction: column; // !important;
}

.cell {
  height: 100%;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 6px;

  &:last-child {
    margin-right: 0;
  }
}

html {
  box-sizing: border-box;
}

body {
  font-size: $font-size-smallest;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.u-display-flex {
  display: flex;
}

.u-flex {
  flex: 1;
}

.body-wrap {
  background: #3f414c;
  transition: filter $splash-animation;

  &--blur {
    filter: blur(4px);
  }
}

#root,
.body-wrap {
  height: 100%;
  width: 100%;
  height: calc(100% - 100px);
}

img {
  width: 100%;
}

// Widget Positioning and Grid Setup
.l-tv-dashboard {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  justify-content: stretch;
  padding-bottom: 0;
  padding: $space-horizontal;
}

// Base Widget Styles
.tv-dashboard-widget {
  background: #35363f;
  background: -moz-linear-gradient(-45deg, #35363f 0%, #2f3038 100%);
  background: -ms-linear-gradient(-45deg, #35363f 0%, #2f3038 100%);
  background: -o-linear-gradient(-45deg, #35363f 0%, #2f3038 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #35363f), color-stop(100%, #2f3038));
  background: -webkit-linear-gradient(-45deg, #35363f 0%, #2f3038 100%);
  background: linear-gradient(135deg, #35363f 0%, #2f3038 100%);
  border-radius: 5px;
  color: $white;
  display: flex;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35363f', endColorstr='#2f3038', GradientType=1 );
  flex-direction: column;
  overflow: hidden;
  padding: $space-vertical $space-horizontal;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }

  .tv-dashboard-widget__header {
    font-weight: 600;
  }

  .tv-dashboard-widget__title {
    color: $white;
    font-size: $font-size-smallest;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: $space-horizontal;
    text-transform: uppercase;
  }

  .tv-dashboard-widget__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: $space-vertical;
    overflow: hidden;
    position: relative;
  }

  .tv-dashboard-widget__title + .tv-dashboard-widget__content {
    padding-top: 0;
  }

  .tv-dashboard-widget__metric-container {
    position: relative;
    width: 100%;
  }

  .tv-dashboard-widget__label {
    font-size: $font-size-smallest;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .tv-dashboard-widget__compare {
    font-size: $font-size-smallest;
    font-weight: 700;
    position: absolute;
    top: -20px;
    margin-left: -$space-horizontal-large;
    text-align: right;
    width: 65px;

    &.tv-dashboard-widget__compare--negative {
      color: $negative-red;
    }

    &.tv-dashboard-widget__compare--positive {
      color: $positive-green;
    }
  }
}

// Metric (Just a plain number widget)
.tv-dashboard-widget--metric {
  .tv-dashboard-widget__content {
    align-items: center;
    justify-content: center;
    margin-top: -$space-vertical;
    text-align: center;
  }

  .metric__metric {
    font-size: $font-size-largest;
    font-weight: 600;
    line-height: 1;
  }
}

// Clock (Widget containing the clock and date)
.tv-dashboard-widget--clock {
  .tv-dashboard-widget__content {
    justify-content: center;
  }

  .clock__clock {
    font-size: $font-size-largest;
    font-weight: 600;
    line-height: 1;
  }
}

// Lists (Visual Lists, Text Lists, Avatar Lists)
.tv-dashboard-widget--list {
  display: flex;
  flex-direction: column;
}

.tv-dashboard-widget--list__list-item {
  display: flex;
  flex-direction: row;
  margin: 0 0 $space-horizontal;

  &:last-of-type {
    margin: 0;
  }

  img {
    margin: 0;
  }
}

// Visual Lists
.tv-dashboard-widget--visual-list {
  $size: 55px;

  .tv-dashboard-widget__visual {
    align-items: center;
    background: $teamtailor-pink;
    border-radius: 50%;
    display: flex;
    height: $size;
    justify-content: center;
    margin-right: $space-horizontal-large;
    width: $size;
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    padding: $space-vertical $space-horizontal;
    width: 100%;
  }

  .tv-dashboard-widget__description {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .tv-dashboard-widget--list__metric {
    font-size: $font-size-normal;
    font-weight: 600;
    line-height: 1;
  }

  &.tv-dashboard-widget--list-horizontal {
    ul {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;

      li {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        &.tv-dashboard-widget--list__list-item {
          margin: 0;
          padding: 0;
        }

        .tv-dashboard-widget__visual {
          margin: 0;
          margin-bottom: $space-vertical-large;
        }

        .tv-dashboard-widget__description {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}

// Text Lists
.tv-dashboard-widget--text-list {
  .tv-dashboard-widget--list__list-item {
    border-bottom: 1px solid #39393f;
    display: flex;
    justify-content: space-between;
    padding: $space-vertical-small 0;

    &:last-of-type {
      border-bottom: 0;
    }

    .count {
      font-weight: 600;
    }
  }
}

.tv-dashboard-widget--latest-hire {
  .tv-dashboard-widget__content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  &__name {
    font-size: $font-size-small;
    margin: 0;
    margin-bottom: $space-vertical-smallest;
  }

  &__job-title {
    font-size: $font-size-smallest;
    font-weight: 400;
    margin: 0;
  }

  &__avatar {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: $font-size-normal;
    height: $avatar-size;
    justify-content: center;
    margin-bottom: $space-vertical-large;
    width: $avatar-size;
  }
}

// Avatar Lists
.tv-dashboard-widget--avatar-list {
  $avatar-size: 30px;

  .tv-dashboard-widget--list {
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }

  .tv-dashboard-widget--list__list-item {
    align-content: center;
    display: flex;
    flex-direction: row;
    min-height: 30px;
    width: 100%;
  }

  .tv-dashboard-widget--description {
    align-items: center;
    display: flex;
    padding-left: $space-horizontal;
    width: 100%;
  }

  .avatar {
    height: $avatar-size;
    width: $avatar-size;
  }

  .avatar-image {
    border-radius: 50%;
    height: $avatar-size;
    width: $avatar-size;
  }

  .count {
    font-weight: 600;
    margin-left: auto;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.horizontal-bar {
  $item-height: 30px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;

  .horizontal-bar__item {
    align-items: center;
    background: $dark-gray;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    height: $item-height;
    margin-bottom: $space-vertical;
    position: relative;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .horizontal-bar__item--filled {
    animation: .4s ease-in-out 0s 1 slideInFromLeft;
    background: $teamtailor-pink;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    height: $item-height;
    position: absolute;
    width: 0%;
  }

  .horizontal-bar__label {
    color: $white;
    display: flex;
    margin: 0 $space-horizontal;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 2;
  }
}

.product-update {
  font-size: $font-size-smaller;
  line-height: 1.4em;

  p {
    margin-top: $space-vertical;
  }
}

.tv-dashboard-splash {
  background: $splash-background;
  color: $white;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $splash-z-index;
  opacity: 0;
  transition: opacity $splash-animation;

  &--show {
    opacity: 1;
  }

  &__radial {
    align-items: center;
    background: radial-gradient(darken($dark-gray, 10%), transparent);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: $splash-z-index + 1;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    z-index: $splash-z-index + 1;

    .tv-dashboard-widget--latest-hire__avatar {
      font-size: $font-size-largest;
      height: $avatar-size * 3;
      margin-bottom: $space-vertical-largest;
      width: $avatar-size * 3;
    }

    .tv-dashboard-widget--latest-hire__name {
      font-size: $font-size-largest;
    }

    .tv-dashboard-widget--latest-hire__job-title {
      font-size: $font-size-larger;
    }

    .tv-dashboard-widget--latest-hire__new-hire {
      font-size: $font-size-larger;
      margin-bottom: $space-vertical-largest;
    }
  }

  &__timer {
    background: $white;
    bottom: 0;
    height: 6px;
    opacity: .4;
    position: fixed;
    right: 0;
    transition: width 1s linear;
    width: 100vw;
    z-index: $splash-z-index + 1;
  }
}

// Footer Logo
.logo-wrap {
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-bottom: $space-vertical-large;
  padding: 0 $space-horizontal $space-vertical;
  width: 100%;

  @media screen and (min-width: $tablet) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }

  &__last-30-days {
    color: $white;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: $font-size-small;
    justify-content: center;
  }

  img {
    display: block;
    height: 40px;
    width: auto;
  }
}
